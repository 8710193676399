const routesArray = [
  {
    name: 'Over',
    path: '/',
  },
  {
    name: 'Muziek',
    path: '/muziek',
  },
  {
    name: 'Verhalen',
    path: '/verhalen',
  },
  {
    name: 'Games',
    path: '/games',
  },
  {
    name: 'Blog',
    path: '/blog',
  },
  {
    name: 'Snippets',
    path: '/snippets',
  },
];

export default routesArray;
