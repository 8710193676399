import React from 'react';
import './background-effect.css'
var seedrandom = require('seedrandom');

export class BackgroundEffect extends React.Component {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  lastTimestamp: number | undefined = undefined;
  oldLocation = window.location.pathname;
  colorSquares: {x: number, y: number, from: number, to: number, curr: number, xOffset: number, yOffset: number, xOffsetTarget: number, yOffsetTarget: number, offsetCurr: number, lastRoute: string}[] = [];

  constructor(props: any) {
    super(props);
    this.canvasRef = React.createRef();
  }

  get ctx() {
    return this.canvasRef.current!.getContext('2d')!;
  }

  get squareSize() {
    return Math.max(40, this.ctx.canvas.width / 40);
  }

  componentDidMount() {
    this.setCanvasSize();
    this.generateSquares();
    this.drawCanvas();

    this.canvasRef!.current!.onresize = () => {
      this.setCanvasSize();
      this.generateSquares();
      this.drawCanvas();
    }
    window.onresize = () => {
      this.setCanvasSize();
      this.generateSquares();
      this.drawCanvas();
    }
  }

  setCanvasSize() {
    const canvas = this.canvasRef.current!;
    const width = canvas.getBoundingClientRect().width;
    const height = canvas.getBoundingClientRect().height;
    const pixelRatio = window.devicePixelRatio ?? 1;
    canvas.width = width * pixelRatio;
    canvas.height = height * pixelRatio;
  }

  drawCanvas(timestamp: number | undefined = undefined) {
    if(timestamp && this.lastTimestamp) {
      this.ctx.resetTransform();
      this.ctx.scale(window.devicePixelRatio ?? 1, window.devicePixelRatio ?? 1);
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
   
      if(this.oldLocation !== window.location.pathname){
        this.colorSquares.forEach((s) => {
          const rnd = seedrandom(s.x + ',' + s.y + ',' + window.location.pathname);
          const lerp = s.from * (1 - s.curr) + s.to * s.curr;
          s.from = lerp;
          s.to = rnd() * 42;
          s.curr = 0;
        });
        this.oldLocation = window.location.pathname;
      }

      this.evadeSquares(timestamp - this.lastTimestamp);
      this.drawSquares(timestamp - this.lastTimestamp);
    }
    this.lastTimestamp = timestamp;
    window.requestAnimationFrame((timestamp) => this.drawCanvas(timestamp));
  }

  generateSquares() {
    this.colorSquares = [];
    for(var x = 0; x < this.ctx.canvas.width / this.squareSize; x++) {
      for(var y = 0; y < this.ctx.canvas.height / this.squareSize; y++) {
        const rnd = seedrandom(x + ',' + y + window.location.pathname);
        const colorOffset = rnd() * 42;
        this.colorSquares.push({x, y, from: colorOffset, to: colorOffset, curr: 0, xOffset: 0, yOffset: 0, xOffsetTarget: 0, yOffsetTarget: 0, offsetCurr: 0, lastRoute: window.location.pathname});
      }
    }
  }

  drawSquares(deltaTime: number) {
    this.colorSquares.forEach((s) => {
      s.curr += deltaTime / 1000;
      s.curr = Math.max(0, Math.min(1, s.curr));
      s.offsetCurr += deltaTime / 500;
      s.offsetCurr = Math.max(0, Math.min(1, s.offsetCurr));
      const lerp = s.from * (1 - s.curr) + s.to * s.curr;
      this.ctx.fillStyle = `rgb(${255 - lerp}, ${222 - lerp}, 0)`;
      const lerpTargetX = s.xOffset * this.easeInOut(1- s.offsetCurr) + s.xOffsetTarget * this.easeInOut(s.offsetCurr);
      const lerpTargetY = s.yOffset * this.easeInOut(1- s.offsetCurr) + s.yOffsetTarget * this.easeInOut(s.offsetCurr);
      this.ctx.fillRect(s.x * this.squareSize + lerpTargetX, s.y * this.squareSize + lerpTargetY, this.squareSize, this.squareSize);
    });
  }

  evadeSquares(deltaTime: number) {
    const evadeBounds = document.getElementById('blokjes-moeten-wegvluchten-hiervoor')?.getBoundingClientRect();
    if(!evadeBounds) return;

    this.colorSquares.forEach(s => {
      const lerpTargetX = s.xOffset * this.easeInOut(1- s.offsetCurr) + s.xOffsetTarget * this.easeInOut(s.offsetCurr);
      const lerpTargetY = s.yOffset * this.easeInOut(1- s.offsetCurr) + s.yOffsetTarget * this.easeInOut(s.offsetCurr);
      const targetX = s.x * this.squareSize + lerpTargetX;
      const targetY = s.y * this.squareSize + lerpTargetY;

      if(s.offsetCurr === 0 || s.offsetCurr === 1) {
        if(this.intersectRect(evadeBounds, {left: targetX, top: targetY, right: targetX + this.squareSize, bottom: targetY + this.squareSize})) {
          s.offsetCurr = 0;
          s.xOffset = lerpTargetX;
          s.yOffset = lerpTargetY;

          let tries = 0;
          do {
            tries++;
          s.xOffsetTarget = s.xOffset + Math.random() * evadeBounds.width * 1.2 * (Math.random() >= 0.5 ? 1 : -1);
          s.yOffsetTarget = s.yOffset + Math.random() * evadeBounds.height * 1.2 * (Math.random() >= 0.5 ? 1 : -1);
          } while(tries < 20 && this.intersectRect(evadeBounds, {left: s.x * this.squareSize + s.xOffsetTarget, top: s.y * this.squareSize + s.yOffsetTarget, right: s.x * this.squareSize + s.xOffsetTarget + this.squareSize, bottom: s.y * this.squareSize + s.yOffsetTarget + this.squareSize}));

          s.lastRoute = window.location.pathname;
        }
        else if(s.lastRoute !== window.location.pathname) {
          s.offsetCurr = 0;
          s.xOffset = lerpTargetX;
          s.yOffset = lerpTargetY;
          s.xOffsetTarget = 0;
          s.yOffsetTarget = 0;
        }
      }
    });
  }

  easeInOut(t: number){
    return t > 0.5 ? 4*Math.pow((t-1),3)+1 : 4*Math.pow(t,3);
  }

  intersectRect(r1: any, r2: any): boolean {
    return !(r2.left > r1.right || 
             r2.right < r1.left || 
             r2.top > r1.bottom ||
             r2.bottom < r1.top);
  }

  componentWillUnmount() {
  
  }

  render() {
    return (<canvas ref={this.canvasRef}></canvas>);
  }
}