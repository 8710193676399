import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import './nav.css';
import routesArray from "./routesArray";

export default function Nav() {
  const location = useLocation();
  const [routes] = useState(routesArray);

  const [leftOffset, setLeftOffset] = useState(0);
  const activeEl = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const elBounds = activeEl.current!.getBoundingClientRect();
    const parBounds = activeEl.current!.parentElement!.getBoundingClientRect();
    setLeftOffset(elBounds.left + elBounds.width / 2 - 8 - parBounds.left);
  }, []);

  return (
    <div className="navbar-wrapper">
      <ul className="navbar">
      {routes.map(r => (
        <li ref={r.path === location.pathname ? activeEl : null} key={r.path}>
          <Link to={r.path}>{r.name}</Link>
        </li>))}
      </ul>
      <div className="bolletjesfeest">
        <div className="bolletje" style={{left: leftOffset}} ></div>
      </div>
    </div>
  );
}