import './style.css';

export default function Over() {
  return (<div className="over-page" id="blokjes-moeten-wegvluchten-hiervoor">
    <h1>Welkom op mijn epische site! Ik ben Sander Vanheste!</h1>
    <p>Kijk hoe de blokjes wegvluchten voor de tekst. Holy shit, wat slim gemaakt!
      En als je naar een andere pagina gaat, vluchten de blokjes weer :)
      Tjonge jonge, wat geniaal bedacht toch, echt zeeeer indrukwekkend. Lekker smooth, en 
      gewoon goed! Jalalalal!
<br/>
      En ik pleur nog meer tekst hier, hehehe
    </p>
    <p>Kijk hoe de blokjes wegvluchten voor de tekst. Holy shit, wat slim gemaakt!
      En als je naar een andere pagina gaat, vluchten de blokjes weer :)
      Tjonge jonge, wat geniaal bedacht toch, echt zeeeer indrukwekkend. Lekker smooth, en 
      gewoon goed! Jalalalal!
<br/>
      En ik pleur nog meer tekst hier, hehehe
    </p>
    <p>Kijk hoe de blokjes wegvluchten voor de tekst. Holy shit, wat slim gemaakt!
      En als je naar een andere pagina gaat, vluchten de blokjes weer :)
      Tjonge jonge, wat geniaal bedacht toch, echt zeeeer indrukwekkend. Lekker smooth, en 
      gewoon goed! Jalalalal!
<br/>
      En ik pleur nog meer tekst hier, hehehe
    </p>
    <p>Kijk hoe de blokjes wegvluchten voor de tekst. Holy shit, wat slim gemaakt!
      En als je naar een andere pagina gaat, vluchten de blokjes weer :)
      Tjonge jonge, wat geniaal bedacht toch, echt zeeeer indrukwekkend. Lekker smooth, en 
      gewoon goed! Jalalalal!
<br/>
      En ik pleur nog meer tekst hier, hehehe
    </p>
    
  </div>);
}