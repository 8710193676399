import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Over from './routes/over';
import Snippets from './routes/snippets';
import Muziek from './routes/muziek';
import Verhalen from './routes/verhalen';
import Games from './routes/games';
import Blog from './routes/blog';
import Nav from './shared/nav';
import {BackgroundEffect} from './shared/background-effect';
import './shared/styles.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="main-wrapper">
        <Nav />
        <BackgroundEffect />
        <Routes>
          <Route path="/" element={<Over/>} />
          <Route path="/muziek" element={<Muziek/>} />
          <Route path="/verhalen" element={<Verhalen/>} />
          <Route path="/games" element={<Games/>} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/snippets" element={<Snippets />} />
        </Routes>
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);